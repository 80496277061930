import { AuthenticationService } from './../../_service/authentication.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    //{ path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    { path: '/upload', title: 'Upload Data', icon:'cloud_upload',class: ''},
    { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
     { path: '/master', title: 'Masters', icon:'group_add',class: ''},
     { path: '/resources', title: 'Resources', icon:'note_add',class: ''},
     { path: '/location', title: 'Location', icon:'account_circle',class: ''},
     { path: '/addLocation', title: 'Add Location', icon:'',class: ''},
     { path: '/school', title: 'Schools List', icon:'',class: ''},
     { path: '/addschool', title: 'Schools Operations', icon:'',class: ''},
     { path: '/teacher', title: 'Teachers List', icon:'',class: ''},
     { path: '/addteacher', title: 'Teacher Operations', icon:'',class: ''},
     { path: '/article', title: 'Article', icon:'',class: ''},
     { path: '/video', title: 'Video', icon:'',class: ''},
     { path: '/ddvideo', title: 'DD Video', icon:'',class: ''},
     { path: '/quiz', title: 'Quiz', icon:'',class: ''},
     { path: '/addcontest', title: 'Add Contest', icon:'',class: ''}, 


     { path: '/addarticle', title: 'Article', icon:'',class: ''},
     { path: '/addvideo', title: 'Video', icon:'',class: ''},
     { path: '/addddvideo', title: 'DD Video', icon:'',class: ''},
     { path: '/addquiz', title: 'Quiz', icon:'',class: ''},
     { path: '/addcontest', title: 'Contest', icon:'',class: ''},

    { path: '/quizdetail', title: 'QuizDetail', icon:'',class: ''},
    { path: '/quizquestion', title: 'QuizQuestion', icon:'',class: ''},

    { path: '/feedback', title: 'Session/Training List', icon:'',class: ''}, 
    { path: '/session', title: 'Session', icon:'',class: ''},
    { path: '/feedbackquestion', title: 'Session training Data', icon:'',class: ''},

    { path: '/feedbackinsertquestion', title: 'Feedback question', icon:'',class: ''},
    { path: '/feedbackdisplayquestion', title: 'Display', icon:'',class: ''} ,
    { path: '/displaycontest', title: 'Contest Display', icon:'',class: ''},
    { path: '/contestevaluation', title: 'Contest Evaluation', icon:'',class: ''},
    { path: '/doit', title: 'Lets Do It', icon:'',class: ''},
    { path: '/category', title: 'Category', icon:'',class: ''},
    { path: '/addcategory', title: 'Add Category', icon:'',class: ''},
    { path: '/audio', title: 'Audio', icon:'',class: ''},
    { path: '/addaudio', title: 'Add Audio', icon:'',class: ''},
    { path: '/podcast', title: 'Podcast', icon:'',class: ''},
    { path: '/facilitator', title: 'Facilitator List', icon:'',class: ''},
    { path: '/addfacilitator', title: 'Add Facilitator', icon:'',class: ''},
    { path: '/ward', title: 'Ward List', icon:'',class: ''},
    { path: '/addward', title: 'Add Ward', icon:'',class: ''},
    { path: '/cohorts', title: 'Cohorts List', icon:'',class: ''},
    { path: '/addcohorts', title: 'Add Cohorts', icon:'',class: ''},
    { path: '/facilitatorfeedbacklist', title: 'Facilitator Feedback', icon:'',class: ''},
    { path: '/facilitatorsession', title: 'Feedback', icon:'',class: ''},
    { path: '/saathiSessionattendencereport', title: 'SaathiSession Attendence Report', icon:'',class: ''},
    { path: '/facilitatorfeedbackreport', title: 'Facilitator Feedback Report', icon:'',class: ''},
    { path: '/starteacherqualitativereport', title: 'Star Teacher Qualitative Report', icon:'',class: ''},
    { path: '/starteacherquantitativereport', title: 'Star Teacher Quantitative Report', icon:'',class: ''},
    { path: '/appusagereport', title: 'App Usage Report', icon:'',class: ''},
    { path: '/teacherfeedbackreport', title: 'Feedback Report', icon:'',class: ''},
    
    { path: '/hmfeedbackreport', title: 'HM Feedback Report', icon:'',class: ''},
    { path: '/letspromise', title: 'Lets Promise', icon:'',class: ''},
    { path: '/addletspromise', title: 'Add Lets Promise', icon:'',class: ''},
    { path: '/doitpaway', title: 'Lesson Plans', icon:'',class: ''},
    { path: '/adddoitpaway', title: 'Add Lesson Plans', icon:'',class: ''},
    { path: '/activitylist', title: 'Worksheet', icon:'',class: ''},
    { path: '/addactivitylist', title: 'Add Worksheet', icon:'',class: ''},
    { path: '/wardcohortmapping', title: 'Ward Cohort Mapping', icon:'',class: ''},
    { path: '/addwardcohortmapping', title: 'Add Ward Cohort Mapping', icon:'',class: ''},
    { path: '/facilitatorcohortmapping', title: 'Facilitator/Coordinator Cohort Mapping', icon:'',class: ''},
    { path: '/addfacilitatorcohortmapping', title: ' Add Facilitator/Coordinator Cohort Mapping', icon:'',class: ''},
    { path: '/letsread', title: 'Teacher' +"'"+'s Desk', icon:'',class: ''},
    { path: '/addletsread', title: 'Teacher' +"'"+'s Desk', icon:'',class: ''},
    { path: '/classroomactivity', title: 'Classroom Activities', icon:'',class: ''},
    { path: '/addclassroomactivity', title: 'Add Classroom Activities', icon:'',class: ''},
    { path: '/radiochannel', title: 'Radio Channels', icon:'',class: ''},
    { path: '/addradiochannel', title: 'Add Radio Channel', icon:'',class: ''},
    { path: '/byline', title: 'By Line', icon:'',class: ''},
    { path: '/addbyline', title: 'Add By Line', icon:'',class: ''},
    { path: '/energizers', title: 'Energizers', icon:'',class: ''},
    { path: '/palibrary', title: 'Pehlay Akshar Library', icon:'',class: ''},
    { path: '/addpalibrary', title: 'Add Pehlay Akshar Library', icon:'',class: ''},
    { path: '/subcategory', title: 'Subcategory', icon:'',class: ''},
    { path: '/addsubcategory', title: 'Add Subcategory', icon:'',class: ''},
    { path: '/hmcohortmapping', title: 'HM Cohort Mapping', icon:'',class: ''},
    { path: '/storyaday', title: 'Story A Day', icon:'',class: ''},
    { path: '/addstoryaday', title: 'Add Story A Day', icon:'',class: ''},
    { path: '/program', title: 'Program', icon:'',class: ''},
    { path: '/program', title: 'Program', icon:'',class: ''},
    { path: '/addprogram', title: 'Add Program', icon:'',class: ''},
    { path: '/programloactionmapping', title: 'Program Location Mapping', icon:'',class: ''},
    { path: '/addprograamelocationmapping', title: 'Add Program Location Mapping', icon:'',class: ''},
    { path: '/studentcontent', title: 'Student Content', icon:'',class: ''},
    { path: '/addstudentcontent', title: 'Add Student Content', icon:'',class: ''},
    { path: '/accesscontrols', title: 'Access Control', icon:'',class: ''},
    { path: '/addarticle1', title: 'Add Article', icon:'',class: ''},
    { path: '/article1', title: 'Article', icon:'',class: ''},
    { path: '/worksheet', title: 'Worksheet', icon:'',class: ''},
    // { path: '/assessments', title: 'Assessments', icon:'',class: ''},
    // { path: '/addassessments', title: 'Add Assessments', icon:'',class: ''},
    { path: '/classroomactivities', title: 'Classroom Activities', icon:'',class: ''},
    { path: '/addclassroomactivities', title: 'Add Classroom Activities', icon:'',class: ''},
    
    

   

   
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(public router:Router,private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  Logout() {
    this.authenticationService.logout(); 
    this.router.navigate(['']);
    // localStorage.removeItem('tokenKey');
    // localStorage.removeItem('Rpgid');
    localStorage.removeItem('name');  


  }
}
