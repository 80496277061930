
import { AuthenticationService } from './../_service/authentication.service';


import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthenticationService]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  isLoading: boolean;
 // loginType : string = 'Browser';

  constructor(public httpClient: HttpClient, public formBuilder: FormBuilder, public router: Router, public _snackBar: MatSnackBar) {
 }

  @Output() messageEvent = new EventEmitter<string>();


  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      Mobile: ['', Validators.required],
      Password: ['', Validators.required],
     // loginType: ['']

    });

  }

  get f() { return this.loginForm.controls; }

 
  onSubmit() {
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
   console.log(JSON.stringify(this.loginForm.value));
   
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    //  this.loginForm.controls['loginType'].setValue(this.loginType);
    // this.httpClient.post('http://13.234.210.84/RPGServices/Login/CreateLogin', JSON.stringify(this.loginForm.value), { headers: headers })
    this.httpClient.post('http://13.234.210.84/RPGServices/Login/CreateLogin', JSON.stringify(this.loginForm.value), { headers: headers })
      .subscribe((response) => {
        
      if (response["success"] == true) {
        
          if (response["Result"].Role == 'Teacher') {

            this.router.navigate(["/login"]);
            this._snackBar.open("Invalid user", "Dismiss", {
              duration: 2000
            });
 
            this.isLoading = false;
          }

          else {
            console.log("successs");
            var sessiontoken = response["Result"].SessionToken;
            localStorage.setItem('tokenKey',sessiontoken);
            var rpgid = response["Result"].RpgId;
            localStorage.setItem('Rpgid', rpgid);
            var name = response["Result"].Name;
            localStorage.setItem('name',name);
            console.log(sessiontoken);
            this.isLoading = false;
            this.router.navigate(["/dashboard1"]);

          }
        }

        else {

          this._snackBar.open("Please enter valid username and password", "Dismiss", {
            duration: 2000
          });
          console.log("fail");
          this.loginForm.reset();
          this.isLoading = false;
          this.submitted = false;
        }
      },(error)=>{
        alert(error);
        this.isLoading = false;
      });
    this.submitted = false;
    this.loginForm.reset();
}

}



