

import { AuthenticationService } from './_service/authentication.service';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CdkTableModule} from '@angular/cdk/table';

import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { MatFormFieldModule, MatProgressSpinnerModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import {MatCardModule} from '@angular/material/card';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';









//import { AddprogramlocationComponent } from './mapping/programlocationmapping/addprogramlocation/addprogramlocation.component';



//import { GoogleChartModule } from './google-chart/google-chart.module';


@NgModule({
  imports: [
    ToastrModule.forRoot(
      {
      //     timeOut:500,
    positionClass:'toast-top-right',
      //     preventDuplicates:false,
      }
    ),
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSnackBarModule,
    HttpClientModule,
    HttpModule,
    MatProgressSpinnerModule, 
    CdkTableModule,
    //GoogleChartModule     
   
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    
    
    
   
    
   
   
    
    
   // AddprogramlocationComponent,
    
    

    
    
    

    
    
   // AddpalibraryComponent,
   // PalibraryComponent
    
    
   
    
    
],
 
  providers: [HttpClient,AuthenticationService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
